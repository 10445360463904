import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    centered: {
        position: 'fixed', /* or absolute */
        top: '50%',
        left: '50%',
      }
}));

const Loading = () => {
    const classes = useStyles();
    return (
        <CircularProgress className={classes.centered} />
    )
}

export default Loading;