import { combineReducers } from "redux"
import loginReducer from './loginReducer'
import registerReducer from './registerReducer'
import modalReducer from './modalReducer'
import extraModalReducer from './extraModalReducer'
import materiReducer from './materiReducer'
import backdropReducer from './backdropReducer'

const rootReducer = combineReducers({
  loginUser: loginReducer,
  registerUser: registerReducer,
  modalReducer: modalReducer,
  extraModalReducer: extraModalReducer,
  materiReducer: materiReducer,
  backdropReducer: backdropReducer,
})

export default rootReducer
