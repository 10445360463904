import * as actionTypes from '../../actionTypes';

const initialState = {
  userRegister: {
    platform : '',
    address : '',
    city: '',
    city_born: '',
    province: '',
    sex: '',
    address : '',
    ktp_id : '',
    coupon : '',
    no_invoice: '',
    fullname : '',
    email: '',
    phone: '',
    password: '',
    showPassword: false,
    confirmPassword: '',
    showConfirmPassword: false,
    }
  }
  
const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_REGISTER:
      return { ...state}
    case actionTypes.SET_VALUES_REGISTER:
      return { ...state, userRegister: {
        ...state.userRegister,
        [action.prop]: action.value,
      }}
    default:
      return state
    }
  }
  
  export default registerReducer;
  